import { MutationTree } from 'vuex';
import { State, User, Phase, Country } from '../types/types';

export enum MutationType {
  SetShowModal = 'SET_SHOW_MODAL',
  SetShowSidebar = 'SET_SHOW_SIDEBAR',
  SetSignUpModal = 'SET_SIGN_UP_MODAL',
  SetLogInModal = 'SET_LOG_IN_MODAL',
  SetResetPasswordModal = 'SET_RESET_PASSWORD_MODAL',

  SetIsLoggedIn = 'SET_IS_LOGGED_IN',

  SetUser = 'SET_USER',
  SetAccessToken = 'SET_ACCESS_TOKEN',
  SetCurrentPhase = 'SET_CURRENT_PHASE',

  DeleteUser = 'DELETE_USER',
  DeleteAccesToken = 'DELETE_ACCESS_TOKEN',
  DeleteCurrentPhase = 'DELETE_CURRENT_PHASE',

  SetAllCountries = 'SET_ALL_COUNTRIES',
}

export type Mutations = {
  [MutationType.SetShowModal](state: State, showModalValue: boolean): void;
  [MutationType.SetShowSidebar](state: State, showSidebarValue: boolean): void;
  [MutationType.SetSignUpModal](state: State, ShowSignUpModalValue: boolean): void;
  [MutationType.SetLogInModal](state: State, ShowLogInModalValue: boolean): void;
  [MutationType.SetResetPasswordModal](state: State, ShowResetPasswordModalValue: boolean): void;

  [MutationType.SetIsLoggedIn](state: State, isLoggedIn: boolean): void;

  [MutationType.SetUser](state: State, user: User): void;
  [MutationType.SetAccessToken](state: State, accessToken: string): void;
  [MutationType.SetCurrentPhase](state: State, currentPhase: Phase): void;
  // eslint-disable-next-line
  [MutationType.DeleteUser](state: State, payload: any): void;
  // eslint-disable-next-line
  [MutationType.DeleteAccesToken](state: State, payload: any): void;
  // eslint-disable-next-line
  [MutationType.DeleteCurrentPhase](state: State, payload: any): void;

  [MutationType.SetAllCountries](state: State, countryList: Country[]): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationType.SetShowModal](state, showModalValue) {
    state.showModal = showModalValue;
  },
  [MutationType.SetShowSidebar](state, showSidebarValue) {
    state.showSidebar = showSidebarValue;
  },
  [MutationType.SetSignUpModal](state, ShowSignUpModalValue) {
    state.showSignUpModal = ShowSignUpModalValue;
  },
  [MutationType.SetLogInModal](state, ShowLogInModalValue) {
    state.showLogInModal = ShowLogInModalValue;
  },
  [MutationType.SetResetPasswordModal](state, ShowResetPasswordModalValue) {
    state.showResetPasswordModal = ShowResetPasswordModalValue;
  },

  [MutationType.SetIsLoggedIn](state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn;
  },

  [MutationType.SetUser](state, user) {
    state.user = user;
  },
  [MutationType.SetAccessToken](state, accessToken) {
    state.accessToken = accessToken;
  },
  [MutationType.SetCurrentPhase](state, currentPhase) {
    state.currentPhase = currentPhase;
  },

  [MutationType.DeleteUser](state) {
    state.user = {} as User;
  },
  [MutationType.DeleteAccesToken](state) {
    state.accessToken = '';
  },
  [MutationType.DeleteCurrentPhase](state) {
    state.currentPhase = {} as Phase;
  },

  [MutationType.SetAllCountries](state, countryList) {
    state.allCountries = countryList;
  },
};
