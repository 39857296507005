
import { defineComponent, ref, PropType, watch } from 'vue';
import { Listbox, ListboxLabel, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';

export default defineComponent({
  components: {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
  },
  props: {
    items: {
      type: Array as PropType<Array<{ id: number | string; name: string }>>,
      default: () => [{}],
    },
    error: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Object as PropType<{ id: number | string; name: string }>,
      default: null,
    },
    showOptionsAbove: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Select option',
    },
  },
  emits: ['update:modelValue'],

  setup(props, context) {
    const selectedItem = ref({ name: props.label, id: -1 });
    // eslint-disable-next-line
    const handleInput = (event: any) => {
      context.emit('update:modelValue', selectedItem.value);
    };

    // eslint-disable-next-line no-undef
    watch(
      props.modelValue,
      () => {
        if (props.modelValue) {
          selectedItem.value.name = props.modelValue.name;
          selectedItem.value.id = props.modelValue.id as number;
        }
      },
      {
        immediate: true,
      }
    );

    return {
      selectedItem,
      handleInput,
    };
  },
});
