import { State, User, Phase, Country } from '../types/types';

export const state: State = {
  // _______________________________________ Modals
  showModal: false,
  showSidebar: false,
  showSignUpModal: false,
  showLogInModal: false,
  showResetPasswordModal: false,

  isLoggedIn: false,

  user: {} as User,
  currentPhase: {} as Phase,
  accessToken: {} as string,

  allCountries: [] as Country[],
};
