
import { defineComponent, computed } from 'vue';
import { useStore } from '../../store';
import { ActionTypes } from '../../store/actions';
import SidebarContent from '../layout/SidebarContent.vue';
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue';

export default defineComponent({
  name: 'Sidebar',
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    SidebarContent,
  },

  setup() {
    const store = useStore();

    const isOpen = computed(() => store.state.showSidebar);
    function closeSidebar() {
      store.dispatch(ActionTypes.HideSidebar);
    }
    return {
      isOpen,
      closeSidebar,
    };
  },
});
