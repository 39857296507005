import { OrganizationType } from '@/enums/OgranizationType';

export interface Cycle {
  id: number;
  startDate: string;
  nationalHalfPoint: string;
  globaPhaseStartDate: string;
  endDate: string;
  scoreWizLink: string;
}

export interface User {
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  nominatedSong: Song;
  country: Country;
  votes?: Vote[] | null;
  isAdmin: boolean;
  organizations?: Organization[];
  emailConfirmed?: boolean;
}

export interface Organization {
  id: number;
  organizationType: OrganizationType;
  country: Country;
}

export interface Song {
  id: string;
  nominatorName: string;
  songName: string;
  songLink: string;
  nationalPointSum: number;
  globalPointSum: number;
  country?: Country;
  cycle?: Cycle;
}

export interface OgaeSong extends Pick<Song, 'id' | 'songName' | 'songLink'> {
  nominator: Pick<User, 'userName'>;
  pointSum: number;
  orgCycleId: number;
  organizationId: number;
}

export interface Country {
  id: number;
  name: string;
  icon: string;
  numberOfSongsInCurrentCycle?: number;
}

export interface Vote {
  songId?: string | null;
  voteWeight: number;
}

export interface Phase {
  id: number;
  startDate: string;
  endDate: string;
  nominationsOnly: boolean;
  nationalHalfPoint: string;
  globaPhaseStartDate: string;
  type: PhaseType;
  scoreWizLink: string;
}

export enum PhaseType {
  National,
  Global,
}

export type State = {
  showModal: boolean;
  showSidebar: boolean;
  showSignUpModal: boolean;
  showLogInModal: boolean;
  showResetPasswordModal: boolean;

  isLoggedIn: boolean;

  user: User;
  currentPhase: Phase;
  accessToken: string;

  allCountries: Country[];
};

export interface WinningSong {
  country: Country;
  song: Song;
}

export interface HistoryCycleDto {
  cycle: Cycle;
  songs: Song[];
}
