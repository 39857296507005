import { Ref, computed, ComputedRef } from 'vue';
import { Status } from '../../utils/validation/types';
import { RegistrationErrorResponse } from '../../utils/api/types';
import { validate, required, length, email, username, password } from '../../utils/validation/validators';
import { Country } from '@/types/types';

export default function useSignUpValidation(
  firstNameInput: Ref<string>,
  lastNameInput: Ref<string>,
  usernameInput: Ref<string>,
  emailInput: Ref<string>,
  passwordInput: Ref<string>,
  passwordConfirmInput: Ref<string>,
  selectedCountryInput: Ref<Country>,
  signUpResponseErrors: Ref<RegistrationErrorResponse>,
  submitButtonClicked: Ref<boolean>
): {
  firstNameStatus: ComputedRef<Status>;
  lastNameStatus: ComputedRef<Status>;
  usernameStatus: ComputedRef<Status>;
  emailStatus: ComputedRef<Status>;
  passwordStatus: ComputedRef<Status>;
  countryStatus: ComputedRef<Status>;
} {
  const validateFirstName = (): Status => {
    return validate(firstNameInput.value, [length({ min: 0, max: 35 })]);
  };
  const validateLastName = (): Status => {
    return validate(lastNameInput.value, [length({ min: 0, max: 35 })]);
  };
  const validateUsername = (): Status => {
    return validate(usernameInput.value, [required(), length({ min: 1, max: 20 }), username()]);
  };
  const validateEmail = (): Status => {
    return validate(emailInput.value, [required(), email()]);
  };
  const validatePassword = (): Status => {
    return validate(passwordInput.value, [required(), length({ min: 8, max: 100 }), password()]);
  };

  const firstNameStatus = computed<Status>(() => {
    if (!submitButtonClicked.value) return { valid: true, message: '' };
    const firstNameValidation = validateFirstName();
    if (!firstNameValidation.valid) return firstNameValidation;
    else if (signUpResponseErrors.value.firstName) {
      return { valid: false, message: signUpResponseErrors.value.firstName };
    } else return { valid: true, message: '' };
  });
  const lastNameStatus = computed<Status>(() => {
    if (!submitButtonClicked.value) return { valid: true, message: '' };
    const lastNameValidation = validateLastName();
    if (!lastNameValidation.valid) return lastNameValidation;
    else if (signUpResponseErrors.value.lastName) return { valid: false, message: signUpResponseErrors.value.lastName };
    else return { valid: true, message: '' };
  });
  const usernameStatus = computed<Status>(() => {
    if (!submitButtonClicked.value) return { valid: true, message: '' };
    const emailValidation = validateUsername();
    if (!emailValidation.valid) return emailValidation;
    else if (signUpResponseErrors.value.userName) return { valid: false, message: signUpResponseErrors.value.userName };
    else return { valid: true, message: '' };
  });
  const emailStatus = computed<Status>(() => {
    if (!submitButtonClicked.value) return { valid: true, message: '' };
    const emailValidation = validateEmail();
    if (!emailValidation.valid) return emailValidation;
    else if (signUpResponseErrors.value.email) return { valid: false, message: signUpResponseErrors.value.email };
    else return { valid: true, message: '' };
  });
  const passwordStatus = computed<Status>(() => {
    if (!submitButtonClicked.value) return { valid: true, message: '' };
    const passwordValidation = validatePassword();
    if (!passwordValidation.valid) return passwordValidation;
    else if (passwordInput.value != passwordConfirmInput.value)
      return { valid: false, message: 'Passwords must match.' };
    else return { valid: true, message: '' };
  });

  const countryStatus = computed<Status>(() => {
    if (!submitButtonClicked.value) return { valid: true, message: '' };
    if (selectedCountryInput.value.id === 0) return { valid: false, message: 'This field is required.' };
    else if (signUpResponseErrors.value.country) {
      return { valid: false, message: signUpResponseErrors.value.country };
    } else return { valid: true, message: '' };
  });

  return {
    firstNameStatus,
    lastNameStatus,
    usernameStatus,
    emailStatus,
    passwordStatus,
    countryStatus,
  };
}
