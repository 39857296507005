
import { defineComponent } from 'vue';
import NFormButton from '../ui/NFormButton.vue';

export default defineComponent({
  name: 'FormSuccsess',
  components: { NFormButton },
  props: {
    showSuccsess: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Title',
    },
    message: {
      type: String,
      default: 'This is a message!',
    },
    buttonName: {
      type: String,
      default: 'I am a button',
    },
  },
  emits: ['buttonClick'],
});
