export class SessionStorage {
  static SetAccessToken(accessToken: string): void {
    sessionStorage.setItem('accessToken', JSON.stringify(accessToken));
  }

  static GetAccessToken(): string | null {
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) return accessToken;
    else return null;
  }

  static DeleteLoggedInUser(): void {
    sessionStorage.removeItem('user');
  }
  static DeleteAccessToken(): void {
    sessionStorage.removeItem('accessToken');
  }
}
