
import { defineComponent, ref } from 'vue';
import { Switch } from '@headlessui/vue';

export default defineComponent({
  name: 'NSwitch',
  components: { Switch },
  props: {
    checked: Boolean,
    name: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change'],

  setup(props) {
    const enabled = ref(props.modelValue);

    return { enabled };
  },
});
