import axios from 'axios';
import { Phase, User, WinningSong } from '../../types/types';
import {
  ServerResponse,
  ErrorResponse,
  RegistrationErrorResponse,
  CountryListResponse,
  SongListResponse,
  LoggedInUserResponse,
  GlobalVoteData,
} from './types';
import { NewUser } from './types';
import { LocalStorage } from '../localStorageService';
import { SessionStorage } from '../sessionStorageService';

const API_URL = process.env.VUE_APP_API_URL + '/api';

export enum ApiStatus {
  OK,
  CaughtError,
  UncaughtError,
}

function authHeader(): HeadersInit {
  const accessTokenLocalStorage = LocalStorage.GetAccessToken();
  const accessTokenSessionStorage = SessionStorage.GetAccessToken();

  let accessToken = '';

  if (accessTokenLocalStorage) accessToken = JSON.parse(accessTokenLocalStorage ? accessTokenLocalStorage : '');
  else accessToken = JSON.parse(accessTokenSessionStorage ? accessTokenSessionStorage : '');
  if (accessToken) {
    return { Authorization: 'Bearer ' + accessToken };
  } else {
    return {};
  }
}

export class Api {
  static async GetCurrentPhase(): Promise<ServerResponse<Phase>> {
    try {
      const response = await axios.get(API_URL + '/phase/current');
      if (response.status == 200) {
        response.status = ApiStatus.OK;
        return response;
      } else {
        response.status = ApiStatus.UncaughtError;
        return response;
      }
    } catch (error) {
      const { response } = error;
      response.status = ApiStatus.UncaughtError;
      return response;
    }
  }

  static async Login(
    username: string,
    password: string
  ): Promise<
    ServerResponse<{
      accessToken: string;
      currentPhase: Phase;
      user: User;
    }>
  > {
    try {
      const response = await axios.post(API_URL + '/authentication/login', {
        username,
        password,
      });
      if (response.status === 200) {
        response.status = ApiStatus.OK;
        return response;
      } else {
        response.status = ApiStatus.UncaughtError;
        return response;
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 401) {
        response.status = ApiStatus.CaughtError;
        return response;
      }
      response.status = ApiStatus.UncaughtError;
      return response;
    }
  }

  public static async SignUp(newUser: NewUser): Promise<ServerResponse<RegistrationErrorResponse | null>> {
    try {
      const response = await axios.post<ServerResponse<RegistrationErrorResponse>>(
        API_URL + '/authentication/register',
        newUser
      );
      if (response.status === 201) {
        return { status: ApiStatus.OK, data: null };
      } else {
        return { status: ApiStatus.UncaughtError, data: null };
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 400) {
        response.status = ApiStatus.CaughtError;
        return response;
      }
      response.status = ApiStatus.UncaughtError;
      return response;
    }
  }

  public static async ResetPasswordWithUsername(username: string): Promise<ServerResponse<ErrorResponse | null>> {
    try {
      const response = await axios.post<ServerResponse<ErrorResponse>>(API_URL + '/user/reset/password/username', {
        userName: username,
      });
      if (response.status === 200) {
        return { status: ApiStatus.OK, data: null };
      } else {
        return { status: ApiStatus.UncaughtError, data: null };
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 400) {
        response.status = ApiStatus.CaughtError;
        return response;
      }
      response.status = ApiStatus.UncaughtError;
      return response;
    }
  }
  public static async ResetPasswordWithEmail(email: string): Promise<ServerResponse<ErrorResponse | null>> {
    try {
      const response = await axios.post<ServerResponse<ErrorResponse>>(API_URL + '/user/reset/password/email', {
        email: email,
      });
      if (response.status === 200) {
        return { status: ApiStatus.OK, data: null };
      } else {
        return { status: ApiStatus.UncaughtError, data: null };
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 400) {
        response.status = ApiStatus.CaughtError;
        return response;
      }
      response.status = ApiStatus.UncaughtError;
      return response;
    }
  }
  public static async SetNewPassword(
    username: string,
    token: string,
    newPassword: string
  ): Promise<ServerResponse<ErrorResponse | null>> {
    try {
      const response = await axios.post<ServerResponse<ErrorResponse>>(API_URL + '/user/set/new/password', {
        userName: username,
        token: token,
        newPassword: newPassword,
      });
      if (response.status === 200) {
        return { status: ApiStatus.OK, data: null };
      } else {
        return { status: ApiStatus.UncaughtError, data: null };
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 400) {
        response.status = ApiStatus.CaughtError;
        return response;
      }
      response.status = ApiStatus.UncaughtError;
      return response;
    }
  }

  static async GetAllCountries(): Promise<ServerResponse<CountryListResponse>> {
    try {
      const response = await axios.get(API_URL + '/countries/all');
      if (response.status == 200) {
        response.status = ApiStatus.OK;
        return response;
      } else {
        response.status = ApiStatus.UncaughtError;
        return response;
      }
    } catch (error) {
      const { response } = error;
      response.status = ApiStatus.UncaughtError;
      return response;
    }
  }
  static async GetAllActiveCountries(): Promise<ServerResponse<CountryListResponse>> {
    try {
      const response = await axios.get(API_URL + '/countries/active');
      if (response.status == 200) {
        response.status = ApiStatus.OK;
        return response;
      } else {
        response.status = ApiStatus.UncaughtError;
        return response;
      }
    } catch (error) {
      const { response } = error;
      response.status = ApiStatus.UncaughtError;
      return response;
    }
  }
  static async GetSongsForCountry(countryId: number): Promise<ServerResponse<SongListResponse>> {
    try {
      const response = await axios.get(API_URL + '/song/' + countryId);
      if (response.status == 200) {
        response.status = ApiStatus.OK;
        return response;
      } else {
        response.status = ApiStatus.UncaughtError;
        return response;
      }
    } catch (error) {
      const { response } = error;
      response.status = ApiStatus.UncaughtError;
      return response;
    }
  }

  public static async ChangeName(firstName: string, lastName: string): Promise<ServerResponse<ErrorResponse | null>> {
    try {
      const response = await axios.post(
        API_URL + '/user/change/name',
        { firstName: firstName, lastName: lastName },
        { headers: authHeader() }
      );
      if (response.status === 200) {
        return { status: ApiStatus.OK, data: null };
      } else {
        return { status: ApiStatus.UncaughtError, data: null };
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 400) {
        return { status: ApiStatus.CaughtError, data: response.data };
      }
      return { status: ApiStatus.UncaughtError, data: response.data };
    }
  }
  public static async ChangeEmail(newEmail: string): Promise<ServerResponse<ErrorResponse | null>> {
    try {
      const response = await axios.post(
        API_URL + '/user/change/email',
        { newEmail: newEmail },
        { headers: authHeader() }
      );
      if (response.status === 200) {
        return { status: ApiStatus.OK, data: null };
      } else {
        return { status: ApiStatus.UncaughtError, data: null };
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 400) {
        return { status: ApiStatus.CaughtError, data: response.data };
      }
      return { status: ApiStatus.UncaughtError, data: response.data };
    }
  }
  public static async GetLoggedInUserData(): Promise<ServerResponse<LoggedInUserResponse | ErrorResponse>> {
    try {
      const response = await axios.get(API_URL + '/user/me', {
        headers: authHeader(),
      });
      if (response.status === 200) {
        return { status: ApiStatus.OK, data: response.data };
      } else {
        return { status: ApiStatus.UncaughtError, data: response.data };
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 400) {
        return { status: ApiStatus.CaughtError, data: response.data };
      }
      return { status: ApiStatus.UncaughtError, data: response.data };
    }
  }
  public static async ChangePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<ServerResponse<ErrorResponse | null>> {
    try {
      const response = await axios.post(
        API_URL + '/user/change/password',
        { oldPassword: oldPassword, newPassword: newPassword },
        { headers: authHeader() }
      );
      if (response.status === 200) {
        return { status: ApiStatus.OK, data: null };
      } else {
        return { status: ApiStatus.UncaughtError, data: null };
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 400) {
        return { status: ApiStatus.CaughtError, data: response.data };
      }
      return { status: ApiStatus.UncaughtError, data: null };
    }
  }
  public static async ChangeCountry(newCountryId: number): Promise<ServerResponse<ErrorResponse | null>> {
    try {
      const response = await axios.post(
        API_URL + '/user/change/country',
        { newCountryId: newCountryId },
        { headers: authHeader() }
      );
      if (response.status === 200) {
        return { status: ApiStatus.OK, data: null };
      } else {
        return { status: ApiStatus.UncaughtError, data: null };
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 400) {
        return { status: ApiStatus.CaughtError, data: response.data };
      }
      return { status: ApiStatus.UncaughtError, data: null };
    }
  }

  public static async NominateSong(songName: string, songLink: string): Promise<ServerResponse<ErrorResponse | null>> {
    try {
      const response = await axios.post(
        API_URL + '/National/Nominate',
        { songName: songName, songLink: songLink },
        { headers: authHeader() }
      );

      if (response.status === 200) {
        return { status: ApiStatus.OK, data: null };
      } else {
        return { status: ApiStatus.UncaughtError, data: null };
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 400) {
        return { status: ApiStatus.CaughtError, data: response.data };
      }
      return { status: ApiStatus.UncaughtError, data: null };
    }
  }
  public static async VoteSongNational(
    songId: string,
    voteWeight: number
  ): Promise<ServerResponse<ErrorResponse | null>> {
    try {
      const response = await axios.post(
        API_URL + '/National/Vote',
        { songId: songId, voteWeight: voteWeight },
        { headers: authHeader() }
      );

      if (response.status === 200) {
        return { status: ApiStatus.OK, data: null };
      } else {
        return { status: ApiStatus.UncaughtError, data: null };
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 400) {
        return { status: ApiStatus.CaughtError, data: response.data };
      }
      return { status: ApiStatus.UncaughtError, data: null };
    }
  }
  public static async VoteSongGlobal(
    songId: string,
    voteWeight: number
  ): Promise<ServerResponse<ErrorResponse | null>> {
    try {
      const response = await axios.post(
        API_URL + '/Global/Vote',
        { songId: songId, voteWeight: voteWeight },
        { headers: authHeader() }
      );

      if (response.status === 200) {
        return { status: ApiStatus.OK, data: null };
      } else {
        return { status: ApiStatus.UncaughtError, data: null };
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 400) {
        return { status: ApiStatus.CaughtError, data: response.data };
      }
      return { status: ApiStatus.UncaughtError, data: null };
    }
  }
  static async GetWinningSongs(): Promise<ServerResponse<WinningSong[]>> {
    try {
      const response = await axios.get(API_URL + '/countries/winners');
      if (response.status == 200) {
        response.status = ApiStatus.OK;
        return response;
      } else {
        response.status = ApiStatus.UncaughtError;
        return response;
      }
    } catch (error) {
      const { response } = error;
      response.status = ApiStatus.UncaughtError;
      return response;
    }
  }
  public static async DeleteSong(songId: string): Promise<ServerResponse<ErrorResponse | null>> {
    try {
      const response = await axios.post(API_URL + '/admin/delete/song', { songId: songId }, { headers: authHeader() });

      if (response.status === 200) {
        return { status: ApiStatus.OK, data: null };
      } else {
        return { status: ApiStatus.UncaughtError, data: null };
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 400) {
        return { status: ApiStatus.CaughtError, data: response.data };
      }
      return { status: ApiStatus.UncaughtError, data: null };
    }
  }
  public static async GetGlobalVoteData(cycleId: number): Promise<ServerResponse<GlobalVoteData | undefined>> {
    try {
      const response = await axios.get(API_URL + `/admin/globalvotedata/${cycleId}`, { headers: authHeader() });

      if (response.status === 200) {
        return { status: ApiStatus.OK, data: response.data };
      } else {
        return { status: ApiStatus.UncaughtError, data: undefined };
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 400) {
        return { status: ApiStatus.CaughtError, data: response.data };
      }
      return { status: ApiStatus.UncaughtError, data: undefined };
    }
  }
  public static async SetScoreWizLink(
    cycleId: number,
    scoreWizLink: string
  ): Promise<ServerResponse<GlobalVoteData | undefined>> {
    try {
      const response = await axios.post(
        API_URL + `/admin/scorewiz/link`,
        {
          cycleId: cycleId,
          scoreWizLink: scoreWizLink,
        },
        { headers: authHeader() }
      );

      if (response.status === 200) {
        return { status: ApiStatus.OK, data: response.data };
      } else {
        return { status: ApiStatus.UncaughtError, data: undefined };
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 400) {
        return { status: ApiStatus.CaughtError, data: response.data };
      }
      return { status: ApiStatus.UncaughtError, data: undefined };
    }
  }
}
