
import { defineComponent, computed } from 'vue';
import { useStore } from '../store';
import { useRouter } from 'vue-router';
import { PhaseType } from '../types/types';
import { ActionTypes } from '@/store/actions';

export default defineComponent({
  name: 'NominateButton',
  setup() {
    const store = useStore();
    const router = useRouter();
    const isLoggedIn = computed(() => store.state.isLoggedIn);
    const isNationalPhase = computed(() => store.state.currentPhase.type === PhaseType.National);
    const nominationsOnly = computed(() => store.state.currentPhase.nominationsOnly);

    const userHasNominatedSong = computed(() => !!store.state.user.nominatedSong);

    function goToNominateSong() {
      router.push('/nominate');
      store.dispatch(ActionTypes.HideSidebar);
    }

    return {
      goToNominateSong,
      isNationalPhase,
      userHasNominatedSong,
      isLoggedIn,
      nominationsOnly,
    };
  },
});
