import { Phase, Country } from '../types/types';

export class LocalStorage {
  static SetAccessToken(accessToken: string): void {
    localStorage.setItem('accessToken', JSON.stringify(accessToken));
  }
  static SetCurrentPhase(currentPhase: Phase): void {
    localStorage.setItem('currentPhase', JSON.stringify(currentPhase));
  }
  static SetCountryList(countryList: Country[]): void {
    localStorage.setItem('countryList', JSON.stringify(countryList));
  }

  static GetAccessToken(): string | null {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && accessToken.length > 10 && accessToken !== undefined) return accessToken;
    else return null;
  }
  static GetCurrentPhase(): Phase | null {
    const currentPhase = localStorage.getItem('currentPhase');
    if (currentPhase && currentPhase.length > 10 && currentPhase !== undefined) return JSON.parse(currentPhase);
    else return null;
  }
  static GetCountryList(): Country[] {
    const countryList = localStorage.getItem('countryList');
    if (countryList === null || !Array.isArray(countryList) || countryList === undefined) return [];
    else return JSON.parse(countryList);
  }

  static DeleteLoggedInUser(): void {
    localStorage.removeItem('user');
  }
  static DeleteAccessToken(): void {
    localStorage.removeItem('accessToken');
  }
  static DeleteCurrentPhase(): void {
    localStorage.removeItem('currentPhase');
  }
}
