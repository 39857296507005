
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NFormButton',
  props: {
    type: {
      type: String,
      default: 'Button',
    },
    name: {
      type: String,
      required: true,
      default: 'Button',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
  },
});
