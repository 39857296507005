import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full" }
const _hoisted_2 = {
  class: "text-sm font-semibold tracking-normal text-black capitalize md:text-sm",
  for: "name"
}
const _hoisted_3 = { class: "" }
const _hoisted_4 = ["id", "type", "value", "placeholder"]
const _hoisted_5 = { class: "flex justify-end" }
const _hoisted_6 = {
  key: 0,
  class: "w-10/12 -mb-2 text-xs text-right text-nominee-danger lg:-mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.name), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", {
        id: _ctx.name,
        class: _normalizeClass([{
          'focus:border-nominee-danger border-1 border-nominee-danger ': _ctx.error,
          'focus:border-gray-900 border-gray-400 ': !_ctx.error,
        }, "w-full px-3 text-base font-light bg-transparent rounded-sm shadow text-nominee-black md:text-base focus:outline-none h-9 md:h-10 border-1"]),
        autocomplete: "on",
        tag: "required",
        type: _ctx.type,
        value: _ctx.modelValue,
        placeholder: _ctx.placeholder,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)), ["delete"]))
      }, null, 42, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.error && _ctx.error != ' ')
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.error), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}