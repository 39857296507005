import { PhaseType } from '@/types/types';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useStore } from '../store';

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
// When using a bundler like webpack, this will automatically benefit from code splitting
// When using Babel, you will need to add the syntax-dynamic-import plugin so that Babel can properly parse the syntax.
// (https://next.router.vuejs.org/guide/advanced/lazy-loading.html)

const routes: Array<RouteRecordRaw> = [
  {
    path: '/notice/:noticeType',
    name: 'Notice',
    component: () => import(/* webpackChunkName: "Notice" */ '../pages/Notice.vue'),
    props: true,
  },
  {
    path: '/',
    name: 'LandingPage',
    component: () => import(/* webpackChunkName: "LandingPage" */ '../pages/LandingPage.vue'),
  },
  {
    path: '/',
    name: 'Base',
    component: () => import(/* webpackChunkName: "Base" */ '../pages/Base.vue'),
    children: [
      {
        path: '/competition',
        name: 'CompetitionBase',
        component: () => import(/* webpackChunkName: "CompetitionBase" */ '../pages/competition/CompetitionBase.vue'),
        children: [
          {
            path: 'nominations',
            name: 'Nominations',
            component: () =>
              import(/* webpackChunkName: "Nominations" */ '../pages/competition/components/Nominations.vue'),
          },
          {
            path: 'nationals',
            name: 'Nationals',
            component: () =>
              import(/* webpackChunkName: "Nationals" */ '../pages/competition/components/Nationals.vue'),
          },
          {
            path: 'finals',
            name: 'Finals',
            component: () => import(/* webpackChunkName: "Finals" */ '../pages/competition/components/Finals.vue'),
          },
        ],
      },
      {
        path: 'country/:icon',
        name: 'Country',
        component: () => import(/* webpackChunkName: "Country" */ '../pages/Country.vue'),
        props: true,
      },
      {
        path: 'nominate',
        name: 'NominateSong',
        component: () => import(/* webpackChunkName: "NominateSong" */ '../pages/NominateSong.vue'),
        meta: { requiresAuth: true, onlyNationalPhase: true, checkIfUserAlreadyNominatedSong: true },
      },
      {
        path: 'rules',
        name: 'Rules',
        component: () => import(/* webpackChunkName: "Rules" */ '../pages/rules/Rules.vue'),
      },
      {
        path: 'history',
        name: 'HistoryBase',
        component: () => import(/* webpackChunkName: "HistoryBase" */ '../pages/history/HistoryBase.vue'),
        children: [
          {
            path: '',
            name: 'HistoryCycles',
            component: () => import(/* webpackChunkName: "HistoryCycles" */ '../pages/history/HistoryCycles.vue'),
          },
          {
            path: 'cycle/:cycleId',
            name: 'HistoryCycle',
            component: () => import(/* webpackChunkName: "HistoryCycle" */ '../pages/history/HistoryCycle.vue'),
            props: true,
          },
          {
            path: 'countries',
            name: 'HistoryCountries',
            component: () => import(/* webpackChunkName: "HistoryCountries" */ '../pages/history/HistoryCountries.vue'),
            props: true,
          },
          {
            path: 'country/:countryId',
            name: 'HistoryCountry',
            component: () => import(/* webpackChunkName: "HistoryCountry" */ '../pages/history/HistoryCountry.vue'),
            props: true,
          },
          {
            path: 'national/country/:countryId?/cycle/:cycleId?',
            name: 'HistorySearch',
            component: () => import(/* webpackChunkName: "HistorySearch" */ '../pages/history/HistorySearch.vue'),
            alias: 'custom-search',
            props: true,
          },
        ],
      },
      {
        path: 'ogae',
        name: 'OgaeBase',
        component: () => import(/* webpackChunkName: "OgaeBase" */ '../pages/ogae/OgaeBase.vue'),
        children: [
          {
            path: 'countries',
            name: 'OgaeCountries',
            component: () => import(/* webpackChunkName: "OgaeCountries" */ '../pages/ogae/OgaeCountries.vue'),
          },
          {
            path: 'organization/:organizationId',
            name: 'OgaeCountry',
            component: () => import(/* webpackChunkName: "OgaeCountry" */ '../pages/ogae/OgaeCountry.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/signup',
        name: 'SignUp',

        component: () => import(/* webpackChunkName: "signUp" */ '../pages/SignUp.vue'),
      },
      {
        path: '/login',
        name: 'Login',

        component: () => import(/* webpackChunkName: "Login" */ '../pages/Login.vue'),
      },
      {
        path: '/SetNewPassword/:username/:token(.*)',
        name: 'SetNewPassword',
        component: () => import(/* webpackChunkName: "SetNewPassword" */ '../pages/SetNewPassword.vue'),
        props: true,
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "Profile" */ '../pages/profile/Profile.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'admin',
        name: 'Admin',
        component: () => import(/* webpackChunkName: "SetNewPassword" */ '../pages/admin/Admin.vue'),
        children: [
          {
            path: 'votes',
            name: 'Votes',
            component: () => import(/* webpackChunkName: "AdminVotes" */ '../pages/admin/AdminVotes.vue'),
            children: [
              {
                path: 'search/country/:countryId?/edition/:cycleId?',
                name: 'AdminVotesUsersSearch',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "AdminVotesUsersSearch" */ '../pages/admin/votes/AdminVotesUsersSearch.vue'
                  ),
              },
              {
                path: 'search/users/:userName?',
                name: 'AdminVotesUsersByUsername',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "AdminVotesUsersByUsername" */ '../pages/admin/votes/AdminVotesUsersByUsername.vue'
                  ),
              },
              {
                path: 'search/edition/:cycleId?',
                name: 'AdminVotesUsersByCountry',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "AdminVotesUsersByCountry" */ '../pages/admin/votes/AdminVotesUsersByCountry.vue'
                  ),
              },
              {
                path: 'votes/edit/user/:userName/edition/:cycleId?',
                name: 'AdminUserVotesEdit',
                component: () =>
                  import(/* webpackChunkName: "AdminUserVotesEdit" */ '../pages/admin/votes/AdminUserVotesEdit.vue'),
                props: true,
              },
            ],
          },

          {
            path: 'settings',
            name: 'AdminSettings',
            component: () => import(/* webpackChunkName: "AdminSettings" */ '../pages/admin/AdminSettings.vue'),
          },
        ],
        meta: { requiresAdminRole: true },
      },
    ],
  },
  // {
  //   path: '/:pathMatch(.*)*',
  //   redirect: '/Notice/404',
  // },
];

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
});

const store = useStore();

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!store.state.isLoggedIn)
      return next({
        name: 'Login',
      });
  }
  if (to.meta.onlyNationalPhase) {
    if (store.state.currentPhase.type == PhaseType.Global)
      return next({
        name: 'LandingPage',
      });
  }
  if (to.meta.onlyGlobalPhase) {
    if (store.state.currentPhase.type == PhaseType.National)
      return next({
        name: 'LandingPage',
      });
  }
  if (to.meta.checkIfUserAlreadyNominatedSong) {
    if (store.state.user.nominatedSong)
      return next({
        name: 'LandingPage',
      });
  }
  if (to.meta.requiresAdminRole) {
    if (!store.state.user.isAdmin)
      return next({
        name: 'LandingPage',
      });
  }
  next();
});

export default router;
