
import { defineComponent, ref, computed } from 'vue';
import { useStore } from '../../store';
import { useRouter, useRoute } from 'vue-router';
import { ActionTypes } from '../../store/actions';
import { validate, required } from '../../utils/validation/validators';
import { Status } from '../../utils/validation/types';
import NFormInput from '../ui/NFormInput.vue';
import NFormButton from '../ui/NFormButton.vue';
import NTitle from '../ui/NTitle.vue';
import NSwitch from '../ui/NSwitch.vue';

export default defineComponent({
  name: 'LoginForm',
  components: { NFormInput, NFormButton, NTitle, NSwitch },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const submitButtonClicked = ref<boolean>(false);
    const isProcessingLoginRequest = ref<boolean>(false);
    const loginFailed = ref<boolean>(false);
    const stayLoggedIn = ref<boolean>(true);

    const usernameInput = ref('');
    const passwordInput = ref('');

    const usernameStatus = computed<Status>(() => {
      if (!submitButtonClicked.value) return { valid: true, message: '' };
      else return validate(usernameInput.value, [required()]);
    });
    const passwordStatus = computed<Status>(() => {
      if (!submitButtonClicked.value) return { valid: true, message: '' };
      else return validate(passwordInput.value, [required()]);
    });

    function resetUsernameStatus() {
      if (loginFailed.value) {
        loginFailed.value = false;
        usernameStatus.value.message = '';
        usernameStatus.value.valid = true;
      }
    }
    function resetPasswordStatus() {
      if (loginFailed.value) {
        loginFailed.value = false;
        passwordStatus.value.message = '';
        passwordStatus.value.valid = true;
      }
    }

    function goToSignUp() {
      if (route.name == 'Login') router.push('/signup');
      else {
        store.dispatch(ActionTypes.HideModal);
        setTimeout(() => store.dispatch(ActionTypes.ShowSignUpModal), 300);
      }
    }
    function goToForgotPassword() {
      store.dispatch(ActionTypes.HideModal);
      setTimeout(() => store.dispatch(ActionTypes.ShowResetPasswordModal), 300);
    }

    async function submitForm() {
      isProcessingLoginRequest.value = true;
      setTimeout(
        async () => {
          if (submitButtonClicked.value === false) submitButtonClicked.value = true;

          if (usernameStatus.value.valid && passwordStatus.value.valid) {
            loginFailed.value = !(await store.dispatch(ActionTypes.Login, {
              username: usernameInput.value,
              password: passwordInput.value,
              stayLoggedIn: stayLoggedIn.value,
            }));
          } else {
            isProcessingLoginRequest.value = false;
            return;
          }
          if (!loginFailed.value) {
            isProcessingLoginRequest.value = false;
            await store.dispatch(ActionTypes.HideModal);
            router.push('/country/' + store.state.user.country.icon);
            return;
          }
          isProcessingLoginRequest.value = false;
          return;
        },
        submitButtonClicked.value ? 3000 : 0
      );
    }

    return {
      submitForm,
      goToSignUp,
      goToForgotPassword,
      loginFailed,
      submitButtonClicked,
      isProcessingLoginRequest,
      stayLoggedIn,
      usernameInput,
      passwordInput,
      usernameStatus,
      passwordStatus,
      resetUsernameStatus,
      resetPasswordStatus,
    };
  },
});
