import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isLoggedIn && !_ctx.userHasNominatedSong && _ctx.isNationalPhase && _ctx.nominationsOnly)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        class: "items-center font-bold text-gray-900 transition duration-300 transform bg-white rounded-sm cursor-pointer select-none focus:outline-none text-opacity bg-opacity-90 hover:bg-opacity-100 hover:text-black",
        style: {"padding":"0.35rem 0.85rem"},
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToNominateSong && _ctx.goToNominateSong(...args)))
      }, " Nominate a Song "))
    : _createCommentVNode("", true)
}