
import { defineComponent, ref, computed } from 'vue';
import { Api, ApiStatus } from '../../utils/api/apiService';
import FormSuccsess from './FormSuccsess.vue';
import { ActionTypes } from '../../store/actions';
import { useStore } from '../../store';
import { validate, length, username, email } from '../../utils/validation/validators';
import { ErrorResponse } from '../../utils/api/types';
import { Status } from '../../utils/validation/types';
import NFormInput from '../ui/NFormInput.vue';
import NFormButton from '../ui/NFormButton.vue';
import NTitle from '../ui/NTitle.vue';
import NSwitch from '../ui/NSwitch.vue';

export default defineComponent({
  name: 'ResetPassword',
  components: { NFormInput, NFormButton, NTitle, NSwitch, FormSuccsess },
  setup() {
    const store = useStore();

    const usernameInput = ref('');
    const emailInput = ref('');

    const submitButtonClicked = ref<boolean>(false);
    const isProcessingRequest = ref<boolean>(false);
    const requestSuccsess = ref<boolean>(false);
    const useUsername = ref<boolean>(false);

    const resetPasswordResponseError = ref<ErrorResponse>({ detail: '' });

    const usernameStatus = computed<Status>(() => {
      if (!submitButtonClicked.value) return { valid: true, message: '' };
      else return validate(usernameInput.value, [length({ min: 1, max: 20 }), username()]);
    });
    const emailStatus = computed<Status>(() => {
      if (!submitButtonClicked.value) return { valid: true, message: '' };
      else return validate(emailInput.value, [length({ min: 1, max: 200 }), email()]);
    });

    function resetUsernameStatus() {
      if (resetPasswordResponseError.value.detail) {
        resetPasswordResponseError.value.detail = '';
        usernameStatus.value.message = '';
        usernameStatus.value.valid = true;
      }
    }
    function resetEmailStatus() {
      if (resetPasswordResponseError.value.detail) {
        resetPasswordResponseError.value.detail = '';
        emailStatus.value.message = '';
        emailStatus.value.valid = true;
      }
    }
    function resetAllErrors() {
      resetPasswordResponseError.value.detail = '';
      usernameStatus.value.message = '';
      emailStatus.value.message = '';
      usernameStatus.value.valid = true;
      emailStatus.value.valid = true;
    }
    function emailButtonClick() {
      useUsername.value = false;
      resetAllErrors();
    }
    function usernameButtonClick() {
      useUsername.value = true;
      resetAllErrors();
    }

    function closeModal() {
      store.dispatch(ActionTypes.HideModal);
    }

    function goToLogin() {
      store.dispatch(ActionTypes.HideModal);
      setTimeout(() => store.dispatch(ActionTypes.ShowLogInModal), 300);
    }

    async function submitForm() {
      isProcessingRequest.value = true;
      setTimeout(
        async () => {
          if (submitButtonClicked.value === false) submitButtonClicked.value = true;
          if (useUsername.value && usernameStatus.value.valid) {
            var resUsername = await Api.ResetPasswordWithUsername(usernameInput.value);
            if (resUsername.status == ApiStatus.OK) {
              requestSuccsess.value = true;
            } else if (
              resUsername.status == ApiStatus.CaughtError &&
              resUsername.data &&
              typeof resUsername.data === typeof resetPasswordResponseError.value
            ) {
              resetPasswordResponseError.value = resUsername.data;
              usernameStatus.value.message = ' ';
              usernameStatus.value.valid = false;
            } else {
              alert('Something went wrong. :(');
            }
          } else if (!useUsername.value && emailStatus.value.valid) {
            var resEmail = await Api.ResetPasswordWithEmail(emailInput.value);
            if (resEmail.status == ApiStatus.OK) {
              requestSuccsess.value = true;
            } else if (
              resEmail.status == ApiStatus.CaughtError &&
              resEmail.data &&
              typeof resEmail.data === typeof resetPasswordResponseError.value
            ) {
              resetPasswordResponseError.value = resEmail.data;
              emailStatus.value.message = ' ';
              emailStatus.value.valid = false;
            } else {
              alert('Something went wrong. :(');
            }
          }
          isProcessingRequest.value = false;
        },
        submitButtonClicked.value ? 3000 : 0
      );
    }

    return {
      submitForm,
      resetAllErrors,
      closeModal,
      goToLogin,
      requestSuccsess,

      emailButtonClick,
      usernameButtonClick,
      submitButtonClicked,
      isProcessingRequest,
      useUsername,

      resetPasswordResponseError,
      usernameInput,
      emailInput,
      usernameStatus,
      emailStatus,
      resetUsernameStatus,
      resetEmailStatus,
    };
  },
});
