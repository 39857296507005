
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NTitle',
  props: {
    tag: {
      type: String,
      required: true,
      default: 'H1',
    },
    content: {
      type: String,
      default: 'Title',
    },
  },
});
