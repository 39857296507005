import { ActionContext, ActionTree } from 'vuex';
import { Mutations, MutationType } from './mutations';
import { State, Country } from '../types/types';
import { Api, ApiStatus } from '../utils/api/apiService';
import { LocalStorage } from '../utils/localStorageService';
import { SessionStorage } from '../utils/sessionStorageService';
import { store } from '.';

export enum ActionTypes {
  HideModal = 'HIDE_MODAL',
  HideSidebar = 'HIDE_SIDEBAR',
  ShowSidebar = 'SHOW_SIDEBAR',
  ShowSignUpModal = 'SHOW_SIGN_UP_MODAL',
  ShowLogInModal = 'SHOW_LOG_IN_MODAL',
  ShowResetPasswordModal = 'SHOW_RESET_PASSWORD_MODAL',

  Login = 'LOGIN',
  Logout = 'LOGOUT',
  CheckIfLoggedIn = 'CHECK_IF_LOGGED_IN',

  GetCurrentPhase = 'GET_CURRENT_PHASE',
  GetAllCountries = 'GET_ALL_COUNTRIES',
  GetActiveCountries = 'GET_ACTIVE_COUNTRIES',
  GetAccessToken = 'GET_ACCESS_TOKEN',
  RefreshLoggedInUserData = 'REFRESH_LOGGED_IN_USER_DATA',
}

type ActionAugments = Omit<ActionContext<State, State>, 'commit'> & {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
  [ActionTypes.HideModal](context: ActionAugments): void;
  [ActionTypes.HideSidebar](context: ActionAugments): void;
  [ActionTypes.ShowSidebar](context: ActionAugments): void;
  [ActionTypes.ShowSignUpModal](context: ActionAugments): void;
  [ActionTypes.ShowLogInModal](context: ActionAugments): void;
  [ActionTypes.ShowResetPasswordModal](context: ActionAugments): void;

  [ActionTypes.Login](
    context: ActionAugments,
    payload: { username: string; password: string; stayLoggedIn: boolean }
  ): Promise<boolean>;
  [ActionTypes.Logout](context: ActionAugments): void;
  [ActionTypes.CheckIfLoggedIn](context: ActionAugments): Promise<boolean>;

  [ActionTypes.GetCurrentPhase](context: ActionAugments): void;
  [ActionTypes.GetAllCountries](context: ActionAugments): Promise<Country[]>;
  [ActionTypes.GetActiveCountries](context: ActionAugments): Promise<Country[]>;
  [ActionTypes.GetAccessToken](context: ActionAugments): string | undefined;
  [ActionTypes.RefreshLoggedInUserData](context: ActionAugments): Promise<void>;
};

export const actions: ActionTree<State, State> & Actions = {
  //_____________________________________________________________________ HideModal
  [ActionTypes.HideModal]({ commit }) {
    commit(MutationType.SetShowModal, false);
  },
  //_____________________________________________________________________ HideSidebar
  [ActionTypes.HideSidebar]({ commit }) {
    commit(MutationType.SetShowSidebar, false);
  },
  //_____________________________________________________________________ ShowSidebar
  [ActionTypes.ShowSidebar]({ commit }) {
    commit(MutationType.SetShowSidebar, true);
  },
  //_____________________________________________________________________ ShowSignUpModal
  async [ActionTypes.ShowSignUpModal]({ commit }) {
    commit(MutationType.SetLogInModal, false);
    commit(MutationType.SetResetPasswordModal, false);
    commit(MutationType.SetSignUpModal, true);
    commit(MutationType.SetShowModal, true);
  },
  //_____________________________________________________________________ ShowLogInModal
  [ActionTypes.ShowLogInModal]({ commit }) {
    commit(MutationType.SetSignUpModal, false);
    commit(MutationType.SetResetPasswordModal, false);
    commit(MutationType.SetLogInModal, true);
    commit(MutationType.SetShowModal, true);
  },
  //_____________________________________________________________________ ShowResetPasswordModal
  [ActionTypes.ShowResetPasswordModal]({ commit }) {
    commit(MutationType.SetShowSidebar, false);
    commit(MutationType.SetSignUpModal, false);
    commit(MutationType.SetLogInModal, false);
    commit(MutationType.SetResetPasswordModal, true);
    commit(MutationType.SetShowModal, true);
  },

  //_____________________________________________________________________ Login
  async [ActionTypes.Login]({ commit }, { username, password, stayLoggedIn }) {
    const result = await Api.Login(username, password);

    if (result.status == ApiStatus.OK) {
      if (stayLoggedIn == true) {
        LocalStorage.SetAccessToken(result.data.accessToken);
      } else {
        SessionStorage.SetAccessToken(result.data.accessToken);
      }

      commit(MutationType.SetUser, result.data.user);
      commit(MutationType.SetAccessToken, result.data.accessToken);
      commit(MutationType.SetIsLoggedIn, true);

      return true;
    } else {
      return false;
    }
  },
  //_____________________________________________________________________ Logout
  [ActionTypes.Logout]({ commit }) {
    commit(MutationType.DeleteAccesToken, {});
    commit(MutationType.DeleteUser, {});
    commit(MutationType.SetIsLoggedIn, false);

    SessionStorage.DeleteAccessToken();
    SessionStorage.DeleteLoggedInUser();

    LocalStorage.DeleteAccessToken();
    LocalStorage.DeleteLoggedInUser();
  },
  //_____________________________________________________________________ CheckIfLoggedIn // BUMP REDUNDANT CODE ALL OVER THE PLACE IN THIS ONE
  async [ActionTypes.CheckIfLoggedIn]({ commit }) {
    const userData = store.state.user;
    if (!userData.userName) {
      const result = await Api.GetLoggedInUserData();
      if ('user' in result.data) {
        // BUMP is this a good solution to union problems?
        commit(MutationType.SetUser, result.data.user);
      }
    }

    const accessTokenLocalStorage = LocalStorage.GetAccessToken();
    if (accessTokenLocalStorage) {
      commit(MutationType.SetAccessToken, accessTokenLocalStorage);
      commit(MutationType.SetIsLoggedIn, true);
      return true;
    }

    const accessTokenSessionStorage = SessionStorage.GetAccessToken();
    if (accessTokenSessionStorage) {
      commit(MutationType.SetAccessToken, accessTokenSessionStorage);
      commit(MutationType.SetIsLoggedIn, true);
      return true;
    }

    commit(MutationType.DeleteAccesToken, null);
    commit(MutationType.DeleteUser, null);
    commit(MutationType.SetIsLoggedIn, false);

    if (accessTokenLocalStorage) LocalStorage.DeleteAccessToken();
    if (accessTokenSessionStorage) SessionStorage.DeleteAccessToken();
    return false;
  },
  //_____________________________________________________________________ GetCurrentPhase
  async [ActionTypes.GetCurrentPhase]({ commit }) {
    const currentPhaseLocalStorage = LocalStorage.GetCurrentPhase();
    if (currentPhaseLocalStorage) commit(MutationType.SetCurrentPhase, currentPhaseLocalStorage);

    const result = await Api.GetCurrentPhase();
    if (result.status == ApiStatus.OK) {
      LocalStorage.SetCurrentPhase(result.data);
      commit(MutationType.SetCurrentPhase, result.data);
    } else return;
  },
  //_____________________________________________________________________ GetAllCountries
  async [ActionTypes.GetAllCountries]({ commit }) {
    const countriesFromStore = store.state.allCountries;
    if (!Array.isArray(countriesFromStore) || !countriesFromStore?.length) {
      const countriesFromLocalStorage = LocalStorage.GetCountryList();
      if (!countriesFromLocalStorage.length) {
        const result = await Api.GetAllCountries();
        commit(MutationType.SetAllCountries, result.data.countryList);
        LocalStorage.SetCountryList(result.data.countryList);

        return result.data.countryList;
      } else {
        commit(MutationType.SetAllCountries, countriesFromLocalStorage);

        return countriesFromLocalStorage;
      }
    } else {
      return countriesFromStore;
    }
  },
  //_____________________________________________________________________ GetActiveCountries
  async [ActionTypes.GetActiveCountries]() {
    const result = await Api.GetAllActiveCountries();

    if (result.status !== ApiStatus.OK) return [];
    return result.data.countryList;
  },
  //_____________________________________________________________________ GetAccessToken
  [ActionTypes.GetAccessToken]({ commit }) {
    const tokenFromStore = store.state.accessToken;
    if (tokenFromStore) return tokenFromStore;
    else if (LocalStorage.GetAccessToken()) {
      const tokenFromLocalStorage = LocalStorage.GetAccessToken();
      if (tokenFromLocalStorage) {
        commit(MutationType.SetAccessToken, tokenFromLocalStorage);
        return tokenFromLocalStorage;
      }
    } else {
      const tokenFromSessionStorage = SessionStorage.GetAccessToken();
      if (tokenFromSessionStorage) {
        commit(MutationType.SetAccessToken, tokenFromSessionStorage);
        return tokenFromSessionStorage;
      }
    }
  },
  //_____________________________________________________________________ RefreshLoggedInUserData
  async [ActionTypes.RefreshLoggedInUserData]({ commit }) {
    const result = await Api.GetLoggedInUserData();
    if ('user' in result.data) {
      // BUMP is this a good solution to union problems?
      commit(MutationType.SetUser, result.data.user);
    }
  },
};
