
import { defineComponent, computed } from 'vue';
import { useStore } from '../store';
import { ActionTypes } from '../store/actions';
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue';

export default defineComponent({
  name: 'ModalBase',
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
  },

  setup() {
    const store = useStore();
    const isOpen = computed(() => store.state.showModal);

    return {
      isOpen,
      closeModal() {
        store.dispatch(ActionTypes.HideModal);
      },
    };
  },
});
