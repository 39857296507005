import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63b0748e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full h-full px-5 py-10" }
const _hoisted_2 = { class: "mb-14 h-1/3" }
const _hoisted_3 = { class: "mb-4 text-3xl text-center text-gray-900" }
const _hoisted_4 = { class: "mb-1 text-xl text-center text-gray-600" }
const _hoisted_5 = { class: "flex mt-8 flex-wrapmx-auto h-1/3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NFormButton = _resolveComponent("NFormButton")!

  return (_openBlock(), _createBlock(_Transition, {
    "enter-active-class": "transition-all ease-in-out transform duration-400",
    "leave-active-class": "transition-all ease-in-out transform duration-400",
    "enter-from-class": "scale-95 opacity-0",
    "enter-to-class": "scale-100 opacity-100",
    "leave-from-class": "opacity-100",
    "leave-to-class": "opacity-0"
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1),
          _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.message), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_NFormButton, {
            type: "button",
            class: "w-7/12 h-10 mx-auto sm:w-5/12 sm:h-12",
            name: _ctx.buttonName,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('buttonClick')))
          }, null, 8, ["name"])
        ])
      ], 512), [
        [_vShow, _ctx.showSuccsess]
      ])
    ]),
    _: 1
  }))
}