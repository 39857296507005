import { Validator, Status, Require, MinMaxOptions, Length, Email, Username, Password, SongLink } from './types';

export function required(): Require {
  return {
    type: 'required',
  };
}

export function length(options: MinMaxOptions): Length {
  return {
    type: 'length',
    options,
  };
}

export function email(): Email {
  return {
    type: 'email',
  };
}

export function username(): Username {
  return {
    type: 'username',
  };
}

export function password(): Password {
  return {
    type: 'password',
  };
}
export function songLink(): SongLink {
  return {
    type: 'songLink',
  };
}

export function validate(value: string, validators: Validator[]): Status {
  for (const validator of validators) {
    if (validator.type === 'required' && (!value || !value.length)) {
      return {
        valid: false,
        message: 'This field is required',
      };
    }
    if (validator.type === 'length' && value.length < validator.options.min) {
      return {
        valid: false,
        message: `This field has a minimum length of ${validator.options.min}.`,
      };
    }
    if (validator.type === 'length' && value.length > validator.options.max) {
      return {
        valid: false,
        message: `This field has a maximum length of ${validator.options.max}.`,
      };
    }
    if (validator.type === 'email') {
      const re =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!re.test(String(value).toLowerCase())) {
        return {
          valid: false,
          message: 'Please enter a valid Email address.',
        };
      }
    }

    if (validator.type === 'username') {
      // eslint-disable-next-line no-useless-escape
      const re = /^[A-Za-z][A-Za-z0-9]*(?:_[A-Za-z0-9]+)*$/;

      if (!re.test(String(value).toLowerCase())) {
        return {
          valid: false,
          message: 'Username can only contain letters, numbers and underscores.',
        };
      }
    }

    if (validator.type === 'password') {
      const re = /[0-9]/; //TEST IT

      if (!re.test(String(value).toLowerCase())) {
        return {
          valid: false,
          message: 'Password must contain at least one number.',
        };
      }
    }
    if (validator.type === 'songLink') {
      // eslint-disable-next-line no-useless-escape
      const ytLinkRegex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = String(value).toLowerCase().match(ytLinkRegex);
      if (!(match && match[2].length === 11)) {
        return {
          valid: false,
          message: 'Please paste a valid YouTube link.',
        };
      }
    }
  }
  return { valid: true };
}
