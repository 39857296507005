
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NFormInput',
  props: {
    error: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ['inputChanged', 'update:modelValue'],
  setup(props, context) {
    // eslint-disable-next-line
    const handleInput = (event: any) => {
      context.emit('inputChanged', event.target.value);
      context.emit('update:modelValue', event.target.value);
    };

    return { handleInput };
  },
});
