
import { defineComponent, ref, computed } from 'vue';
import { useStore } from '@/store';
import { useRoute, useRouter } from 'vue-router';

import { ActionTypes } from '../../store/actions';
import { Api, ApiStatus } from '../../utils/api/apiService';
import { RegistrationErrorResponse } from '../../utils/api/types';
import useSignUpValidation from '../composables/useSignUpValidation';

import FormSuccsess from './FormSuccsess.vue';
import NFormSelect from '../ui/NFormSelect.vue';
import NFormButton from '../ui/NFormButton.vue';
import NFormInput from '../ui/NFormInput.vue';
import NTitle from '../ui/NTitle.vue';
import { Country } from '@/types/types';

export default defineComponent({
  name: 'SignUpForm',
  components: {
    NFormSelect,
    NFormInput,
    NFormButton,
    NTitle,
    FormSuccsess,
  },
  async setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const firstNameInput = ref<string>('');
    const lastNameInput = ref<string>('');
    const usernameInput = ref<string>('');
    const emailInput = ref<string>('');
    const passwordInput = ref<string>('');
    const passwordConfirmInput = ref<string>('');

    const selectedCountryInput = ref<Country>({ name: '', id: 0, icon: '' });

    const submitButtonClicked = ref<boolean>(false);
    const isProcessingSignUpRequest = ref<boolean>(false);
    const signUpIsSuccsessful = ref<boolean>(false);

    const signUpResponseErrors = ref<RegistrationErrorResponse>({
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      password: '',
      country: '',
    });

    const allCountries = await store.dispatch(ActionTypes.GetAllCountries);

    const { firstNameStatus, lastNameStatus, usernameStatus, emailStatus, passwordStatus, countryStatus } =
      useSignUpValidation(
        firstNameInput,
        lastNameInput,
        usernameInput,
        emailInput,
        passwordInput,
        passwordConfirmInput,
        selectedCountryInput,
        signUpResponseErrors,
        submitButtonClicked
      );

    const submitButtonDisabled =
      computed<boolean>(
        () =>
          (!usernameStatus.value.valid ||
            !emailStatus.value.valid ||
            !passwordStatus.value.valid ||
            !countryStatus.value.valid) &&
          submitButtonClicked.value
      ) || isProcessingSignUpRequest.value;

    function resetFirstNameStatus() {
      if (signUpResponseErrors.value.firstName) {
        signUpResponseErrors.value.firstName = '';
        firstNameStatus.value.message = '';
        firstNameStatus.value.valid = true;
      }
    }
    function resetLastNameStatus() {
      if (signUpResponseErrors.value.lastName) {
        signUpResponseErrors.value.lastName = '';
        lastNameStatus.value.message = '';
        lastNameStatus.value.valid = true;
      }
    }
    function resetUsernameStatus() {
      if (signUpResponseErrors.value.userName) {
        signUpResponseErrors.value.userName = '';
        usernameStatus.value.message = '';
        usernameStatus.value.valid = true;
      }
    }
    function resetEmailStatus() {
      if (signUpResponseErrors.value.email) {
        signUpResponseErrors.value.email = '';
        emailStatus.value.message = '';
        emailStatus.value.valid = true;
      }
    }
    function resetPasswordStatus() {
      if (signUpResponseErrors.value.password) {
        signUpResponseErrors.value.password = '';
        passwordStatus.value.message = '';
        passwordStatus.value.valid = true;
      }
    }
    function resetCountryStatus() {
      if (signUpResponseErrors.value.country) {
        signUpResponseErrors.value.country = '';
        countryStatus.value.message = '';
        countryStatus.value.valid = true;
      }
    }
    function goToLogin() {
      if (route.name == 'SignUp') router.push('/login');
      else {
        store.dispatch(ActionTypes.HideModal);
        setTimeout(() => store.dispatch(ActionTypes.ShowLogInModal), 300);
      }
    }
    async function submitForm() {
      isProcessingSignUpRequest.value = true;
      setTimeout(
        async () => {
          if (submitButtonClicked.value === false) submitButtonClicked.value = true;

          if (
            usernameStatus.value.valid &&
            emailStatus.value.valid &&
            passwordStatus.value.valid &&
            countryStatus.value.valid
          ) {
            var res = await Api.SignUp({
              firstname: firstNameInput.value,
              lastname: lastNameInput.value,
              username: usernameInput.value,
              password: passwordInput.value,
              email: emailInput.value,
              countryid: selectedCountryInput.value.id,
            });

            if (res.status == ApiStatus.OK) {
              signUpIsSuccsessful.value = true;
              isProcessingSignUpRequest.value = false;
            } else if (res.status == ApiStatus.CaughtError) {
              if (res.data && typeof res.data === typeof signUpResponseErrors.value)
                signUpResponseErrors.value = res.data;
            } else if (res.status == ApiStatus.UncaughtError) alert('Something went wrong. :(');
          }
          isProcessingSignUpRequest.value = false;
          return;
        },
        submitButtonClicked.value ? 3000 : 0
      );
    }

    return {
      allCountries,

      submitForm,
      goToLogin,
      signUpIsSuccsessful,
      isProcessingSignUpRequest,

      submitButtonClicked,
      submitButtonDisabled,

      firstNameInput,
      lastNameInput,
      usernameInput,
      emailInput,
      passwordInput,
      passwordConfirmInput,
      selectedCountryInput,

      firstNameStatus,
      lastNameStatus,
      usernameStatus,
      emailStatus,
      passwordStatus,
      countryStatus,

      resetFirstNameStatus,
      resetLastNameStatus,
      resetUsernameStatus,
      resetEmailStatus,
      resetPasswordStatus,
      signUpResponseErrors,
      resetCountryStatus,
    };
  },
});
