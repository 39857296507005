
import { defineComponent, computed } from 'vue';
import { useStore } from './store';
import ModalBase from './components/ModalBase.vue';
import Sidebar from './components/layout/Sidebar.vue';
import SignUpForm from './components/forms/SignUpForm.vue';
import LoginForm from './components/forms/LoginForm.vue';
import ResetPassword from './components/forms/ResetPassword.vue';
import { ActionTypes } from './store/actions';

export default defineComponent({
  name: 'App',

  components: {
    ModalBase,
    SignUpForm,
    LoginForm,
    ResetPassword,
    Sidebar,
  },

  setup() {
    const store = useStore();

    const showSignUpModal = computed(() => store.state.showSignUpModal);
    const showLogInModal = computed(() => store.state.showLogInModal);
    const showResetPasswordModal = computed(() => store.state.showResetPasswordModal);

    store.dispatch(ActionTypes.GetCurrentPhase);
    store.dispatch(ActionTypes.CheckIfLoggedIn);

    return {
      showSignUpModal,
      showLogInModal,
      showResetPasswordModal,
    };
  },
});
