
import { defineComponent, computed } from 'vue';
import { useStore } from '../../store';
import { useRouter } from 'vue-router';
import { ActionTypes } from '../../store/actions';
import { PhaseType } from '@/types/types';
import NominateButton from '../NominateButton.vue';

export default defineComponent({
  components: { NominateButton },

  setup() {
    const store = useStore();
    const router = useRouter();

    const isLoggedIn = computed(() => store.state.isLoggedIn);
    const user = computed(() => store.state.user);
    const isUserAdmin = user.value.isAdmin;
    const isGlobalPhase = computed(() => store.state.currentPhase.type === PhaseType.Global);

    const usersCountryPath = computed(() => {
      if (user.value != undefined && user.value != null) return '/country/' + user.value.country.icon;
      else return '/country/US';
    });

    function logOut() {
      store.dispatch(ActionTypes.HideSidebar);
      store.dispatch(ActionTypes.Logout);
      router.push('/');
    }
    function goHome() {
      router.push('/');
      store.dispatch(ActionTypes.HideSidebar);
    }
    function goToProfile() {
      router.push('/profile');
      store.dispatch(ActionTypes.HideSidebar);
    }
    function goToUsersCountry() {
      store.dispatch(ActionTypes.HideSidebar);
      router.push('/country/' + user.value.country.icon);
    }
    function goToSignUp() {
      store.dispatch(ActionTypes.HideSidebar);
      router.push('/signup');
    }
    function goToLogin() {
      store.dispatch(ActionTypes.HideSidebar);
      router.push('/login');
    }
    const goToRules = () => {
      store.dispatch(ActionTypes.HideSidebar);
      router.push('/rules');
    };
    const goToAdmin = () => {
      store.dispatch(ActionTypes.HideSidebar);
      router.push('/admin');
    };
    const goToHistory = () => {
      store.dispatch(ActionTypes.HideSidebar);
      router.push('/history');
    };

    const goToOgae = () => {
      store.dispatch(ActionTypes.HideSidebar);
      router.push('/ogae/countries');
    };

    const goToCompetition = () => {
      store.dispatch(ActionTypes.HideSidebar);
      router.push('/competition');
    };
    return {
      isLoggedIn,
      isUserAdmin,
      user,
      isGlobalPhase,
      logOut,
      goToLogin,
      goToSignUp,
      goHome,
      goToProfile,
      goToRules,
      goToAdmin,
      goToCompetition,
      goToHistory,
      goToUsersCountry,
      goToOgae,
      usersCountryPath,
    };
  },
});
