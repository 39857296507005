import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Suspense as _Suspense, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "antialiased font-normal section font-openSans" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignUpForm = _resolveComponent("SignUpForm")!
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_ResetPassword = _resolveComponent("ResetPassword")!
  const _component_ModalBase = _resolveComponent("ModalBase")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_ModalBase, null, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_Suspense, null, {
            default: _withCtx(() => [
              (_ctx.showSignUpModal)
                ? (_openBlock(), _createBlock(_component_SignUpForm, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })),
          (_ctx.showLogInModal)
            ? (_openBlock(), _createBlock(_component_LoginForm, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.showResetPasswordModal)
            ? (_openBlock(), _createBlock(_component_ResetPassword, { key: 1 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_Sidebar),
      _createVNode(_component_router_view)
    ])
  ]))
}