import { GetterTree } from 'vuex';
import { State, Phase } from '../types/types';

export type Getters = {
  getCurrentPhase(state: State): Phase;
};

export const getters: GetterTree<State, State> & Getters = {
  getCurrentPhase(state) {
    return state.currentPhase;
  },
};
